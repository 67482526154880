import AboutSubPage from '../../components/general/AboutSubPage'
import PopUpStart from '../../components/general/PopUpStart'
import Seperator from '../../components/general/Seperator'
import Slider from '../../components/general/Slider'
import HeroSubpage from '../../components/general/HeroSubpage'
import DropdownWrapper from '../../components/general/DropdownWrapper'
import HowItWorks from '../../components/general/HowItWorks'
import SubPagePopup from '../../components/general/SubPagePopup'
import { getHandyData, getHowItWorksData, getPopUpImgData, getSliderData } from '../../hooks/cmsApiCalls'
import { useQuery } from '@tanstack/react-query'
import ErrorPage from '../404/ErrorPage'
import BaseSeo from '../../components/layout/BaseSeo'
import Loading from '../../components/common/Loading'

function Handyverstoss() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['handyData'],
        queryFn: getHandyData,
    })

    const {
        error: howItWorksError,
        data: howItWorksData,
        isLoading: howItWorksLoading,
    } = useQuery({
        queryKey: ['howItWorksData'],
        queryFn: getHowItWorksData,
    })

    const {
        error: sliderError,
        data: sliderData,
        isLoading: sliderLoading,
    } = useQuery({
        queryKey: ['sliderData'],
        queryFn: getSliderData,
    })

    const {
        error: popupImgError,
        data: popupImgData,
        isLoading: popupImgLoading,
    } = useQuery({
        queryKey: ['popupImgData'],
        queryFn: getPopUpImgData,
    })

    return (
        <>
            {(error || sliderError || howItWorksError || popupImgError) && <ErrorPage />}
            {(isLoading || sliderLoading || howItWorksLoading || popupImgLoading) && <Loading />}
            {data && sliderData && howItWorksData && popupImgData && (
                <>
                    <BaseSeo seo={data?.attributes?.seo} />
                    <HeroSubpage content={data} />
                    <Slider slides={sliderData || []} />
                    <AboutSubPage content={data} />
                    <div className="hidden md:block w-full">
                        <Seperator content={data?.attributes?.separator}/>
                    </div>
                    <div className="mt-16 lg:hidden" />
                    <DropdownWrapper content={data?.attributes.faq} />
                    <PopUpStart imageUrl={String(popupImgData?.attributes.image.data.attributes.url)} verstoss="handyverstoss" />
                    <HowItWorks content={howItWorksData} />
                    <SubPagePopup firstScreenType="handyverstoss" />
                </>
            )}
        </>
    )
}

export default Handyverstoss
