import { LeistungenContentChlidType } from '../../types/Types'
import { API } from '../../services/constants'

function ResponsibiliesAdditional({ content }: LeistungenContentChlidType) {
    return (
        <div className="w-full max-w-screen-xl mx-auto pt-20 md:pt-16 pb-8 md:pb-16 flex space-x-6 px-8 xl:px-0">
            <div className="w-full md:w-1/2">
                <h3 className=" text-4xl text-secondary mb-4 sm:mb-6 md:mb-8 max-w-md">{content?.attributes?.responsibility_headline2}</h3>
                {content?.attributes?.weitere_bereiche?.map((item) => (
                    <h4 className="w-full border-b border-primary py-4 font-medium">{item.responsibility}</h4>
                ))}
            </div>
            <div className="hidden md:flex w-1/2 px-8">
                <img src={API.img + content?.attributes?.about_image?.data?.attributes?.url} alt="" className="object-cover h-full" />
            </div>
        </div>
    )
}

export default ResponsibiliesAdditional
