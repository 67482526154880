import React, { useContext, useRef } from 'react'
import Modal from '../modal/Modal'
import { ReactComponent as RotesPlus } from '../../res/RotesPlus.svg'
import { VerstossType } from '../../types/Types'
import { ModalContext } from '../contexts/ModalContext'

type SubPageType = {
    firstScreenType: VerstossType
}

function SubPagePopup({ firstScreenType }: SubPageType) {
    const modalCon = useContext(ModalContext)
    const scrollToRef = () => {
        document.getElementById('subpage-modal')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }
    return (
        <>
            <div className=" w-full text-white pb-8 lg:py-32 pt-8 overflow-hidden bg-white " id="subpage-modal">
                <div className="relative bg-primary max-w-screen-lg mx-auto px-8 lg:px-28 py-12 rounded-lg shadow-lg">
                    <RotesPlus className=" hidden md:block  absolute w-28 -top-14 left-6" />
                    <Modal
                        scrollToRef={scrollToRef}
                        modalId={1}
                        closeModal={() => modalCon?.trigger(1)}
                        firstScreenType={firstScreenType}
                        isSubPage
                    />
                </div>
            </div>
        </>
    )
}

export default SubPagePopup
