import { API } from '../../services/constants'
import { HomeContentChildType } from '../../types/Types'
import Seperator3 from './Seperator3'

function Placeholder({ content }: HomeContentChildType) {
    return (
        <div className="w-full sm:mb-4 md:mb-12 sm:mt-8 md:mt-12 lg:mt-16s">
            <img
                src={API.img + content?.attributes.middle_image.data.attributes.url}
                alt={API.img + content?.attributes.middle_image.data.attributes.alternativeText}
                className="w-full h-[300px] md:h-[500px] object-cover z-0"
            />
            <div className="-mt-24 relative">
                <Seperator3 text={content?.attributes.seperator_text} />
            </div>
        </div>
    )
}

export default Placeholder
