import useCookie from 'react-use-cookie'
import { FAQType } from '../../types/Types'

const MAPS_TOKEN = 'mapsEnabled'
const MAPS_TOKEN_VALUE = {
    enabled: '1',
    disabled: '0',
}

type MapsType = {
    content:
        | {
              hero_headline: string
              hero_subtitle: string
              about_quote: string
              adress_street: string
              adress_plz: string
              about_faq: {
                  heading: string
                  question_and_answer: FAQType[]
              }
              about_image: {
                  data: {
                      id: number
                      attributes: {
                          name: string
                          alternativeText: string
                          caption: string
                          url: string
                      }
                  }
              }
          }
        | undefined
}

function Maps({ content }: MapsType) {
    const [mapsCookie, setMapsCookie] = useCookie(MAPS_TOKEN, MAPS_TOKEN_VALUE.disabled)

    if (!content) {
        return null
    }

    return (
        <div className="md:pt-8 pb-16 sm:pb-24 md:pb-40 w-full relative flex-col hidden sm:block">
            {mapsCookie === MAPS_TOKEN_VALUE.disabled && (
                <div className="flex p-0 h-[300px] w-full order-2 md:h-[400px] bg-base-200">
                    <button
                        onClick={() => setMapsCookie(MAPS_TOKEN_VALUE.enabled)}
                        className="m-auto bg-secondary cursor-pointer text-white rounded-2xl py-2 px-6 font-normal  active:scale-pressed duration-100 ease-in-out text-center ">
                        Google Maps Karte anzeigen
                    </button>
                </div>
            )}
            {mapsCookie === MAPS_TOKEN_VALUE.enabled && (
                <iframe
                    title="Google Maps Standort von Einfach Klar"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2492.1485360723277!2d12.388880476986046!3d51.34517922254072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f816703700a3%3A0x4c085ca5eaedaeca!2sRosa-Luxemburg-Stra%C3%9Fe%2027%2C%2004103%20Leipzig!5e0!3m2!1sde!2sde!4v1721131559638!5m2!1sde!2sde"
                    className="p-0 h-[300px] w-full order-2 md:h-[400px]"
                    loading="lazy"></iframe>
            )}
            <div className="bg-primary  p-4 w-full order-1 sm:w-auto sm:absolute sm:right-40 sm:-translate-y-14 ">
                <h4 className="text-2xl text-white font-semibold pb-4">
                    So finden Sie zu <span className="text-accent">UNS!</span>
                </h4>
                <p className="text-white pb-4">
                    {content.adress_street}
                    <br /> {content.adress_plz}
                </p>
            </div>
        </div>
    )
}

export default Maps
