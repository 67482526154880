import { HashLink } from 'react-router-hash-link'
import { useState, useEffect, useRef, RefObject } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import Button from '../general/Button'
import { API } from '../../services/constants'
import DropdownMenu from '../general/DropdownMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { InheritContentParentType } from '../../types/Types'
import { motion } from 'framer-motion'

type LogoType = {
    logos?: InheritContentParentType
}

export default function Header(props: LogoType) {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const navigate = useNavigate()
    const showDropdown = () => setIsDropdownVisible(true)
    const hideDropdown = () => setIsDropdownVisible(false)

    const [nav, setNav] = useState(false)

    const handleNav = () => setNav(!nav)

    const navRef: RefObject<HTMLUListElement> = useRef(null)

    // If nav is open and click was outside of the sidebar, close it
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (nav && navRef.current && !navRef.current.contains(event.target)) {
                setNav(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [nav])

    const location = useLocation()
    const [activePage, setActivePage] = useState<string>('')

    useEffect(() => {
        setActivePage(location.pathname)
    }, [location])

    const [responsiveDropdown, setResponsiveDropdown] = useState(false)

    return (
        <div className={`fixed w-full z-50 md:bg-none top-0 md:px-6 xl:px-0 bg-primary`}>
            {/* div for Desktop view  */}
            <div className="hidden md:flex max-w-screen-xl mx-auto h-20 text-white justify-between">
                <div className="my-auto w-32 lg:w-fit">
                    <HashLink to={'/#_'}>
                        <img src={API.img + props.logos?.attributes?.logo_white?.data?.attributes?.url} alt="Logo" className="w-[180px]" />
                    </HashLink>
                </div>
                <div className="flex lg:grow justify-end">
                    <ul className="flex flex-row items-center justify-end uppercase md:gap-8 lg:gap-12 xl:gap-16 lg:pr-12 w-fit lg:w-full ">
                        <li
                            className={`justify-center  decoration-secondary decoration-2 hover:scale-hover hover:underline hover:underline-offset-4 active:scale-pressed ease-in-out duration-100 ${
                                activePage === '/' ? 'underline underline-offset-4 ' : 'no-underline'
                            }`}>
                            <HashLink to={'/#_'}>Home</HashLink>
                        </li>
                        <li
                            className={`flex whitespace-nowrap justify-center decoration-secondary decoration-2 hover:scale-hover hover:underline hover:underline-offset-4 active:scale-pressed ease-in-out duration-100 ${
                                activePage === '/about' ? 'underline underline-offset-4 ' : 'no-underline'
                            }`}>
                            <HashLink to={'/about#_'}>Über Uns</HashLink>
                        </li>
                        <li
                            className={`justify-center decoration-secondary decoration-2 hover:scale-hover hover:underline hover:underline-offset-4 active:scale-pressed ease-in-out duration-100 ${
                                activePage === '/leistungen' ? 'underline underline-offset-4 ' : 'no-underline'
                            }`}>
                            <HashLink to={'/leistungen#_'}>Expertise & Ablauf</HashLink>
                        </li>
                        <li
                            className="relative justify-center whitespace-nowrap flex-nowrap no-underline decoration-secondary decoration-2 hover:underline hover:underline-offset-4 "
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}>
                            <HashLink>
                                Verstö<span className="normal-case">ß</span>e
                            </HashLink>
                            {isDropdownVisible && <DropdownMenu />}
                        </li>
                    </ul>
                    <div className="flex items-center pl-8 lg:px-12">
                        <Button text="Login" />
                    </div>
                </div>
            </div>

            {/* Responsive Sidebar */}
            <div className="flex text-white">
                {' '}
                <ul
                    ref={navRef}
                    className={`
                            overflow-y-scroll overflow-x-hidden fixed left-0 top-0 w-[60%] h-full border-r border-r-primary bg-primary transform
                        ${nav ? ' translate-x-0 ease-in-out duration-500' : '-translate-x-full ease-in-out duration-500'}`}>
                    {/* TODO */}
                    <li className="p-4 pt-[1.3rem]">
                        <img
                            onClick={() => {
                                navigate('/#_')
                                handleNav()
                                window.scrollTo(0, 0)
                            }}
                            src={API.img + props.logos?.attributes?.logo_white?.data?.attributes?.url}
                            alt="Logo"
                            className="w-40 h-12 cursor-pointer "
                        />
                    </li>
                    <HashLink onClick={() => handleNav()} to={'/#_'}>
                        <li className="p-4 border-b border-gray-400 active:bg-primary-focus">Home</li>
                    </HashLink>
                    <HashLink onClick={() => handleNav()} to={'/about#_'}>
                        <li className="p-4 border-b border-gray-400 active:bg-primary-focus">Über Uns</li>
                    </HashLink>
                    <HashLink onClick={() => handleNav()} to={'/leistungen#_'}>
                        <li className="p-4 border-b border-gray-400 active:bg-primary-focus">Expertise & Ablauf</li>
                    </HashLink>
                    <li
                        onClick={() => setResponsiveDropdown(!responsiveDropdown)}
                        className="p-4 border-b border-gray-400 active:bg-primary-focus flex justify-between">
                        Verstöße
                        <motion.svg
                            initial={{ rotate: 0 }}
                            animate={{ rotate: responsiveDropdown ? 540 : 0 }}
                            transition={{
                                duration: 0.2,
                                type: 'ease',
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </motion.svg>
                    </li>
                    {responsiveDropdown && (
                        <>
                            <HashLink onClick={() => handleNav()} to={'/geschwindigkeitsverstoss#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Geschwindigkeitsverstoß</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/alkoholdelikte#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Alkoholverstoß</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/rotlichtverstoss#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Rotlichtverstoß</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/abstandsverstoss#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Abstandsverstoß</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/handyverstoss#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Handyverstoß</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/park-abschleppmassnahmen#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Park- und Abschleppmaßnahmen</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/illegale-autorennen#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Illegale Autorennen</li>
                            </HashLink>
                            <HashLink onClick={() => handleNav()} to={'/anderer-verstoss#_'}>
                                <li className="pl-8 p-4 border-b border-gray-400 active:bg-primary-focus">Sonstiger Verstoß</li>
                            </HashLink>
                        </>
                    )}
                </ul>
                <div className="flex p-4 md:hidden w-full bg-white items-center">
                    <img
                        onClick={() => navigate('/#_')}
                        src={API.img + props.logos?.attributes?.logo_black?.data?.attributes?.url}
                        alt="Logo"
                        className="w-48 h-14 "
                    />
                    {nav ? (
                        <AiOutlineClose onClick={handleNav} size={35} className="fill-secondary ml-auto" />
                    ) : (
                        <AiOutlineMenu onClick={handleNav} size={35} className="fill-secondary ml-auto" />
                    )}
                </div>
            </div>
        </div>
    )
}
