import Hero from '../../components/general/Hero'
import Placeholder from '../../components/general/Placeholder'
import Seperator from '../../components/general/Seperator'
import Slider from '../../components/general/Slider'
import ViolationOverview from '../../components/general/ViolationOverview'
import Seperator2 from '../../components/general/Seperator2'
import NewsWrapper from '../../components/general/NewsWrapper'
import AboutUs from '../../components/general/AboutUs'
import ContactFormWrapper from '../../components/general/ContactFormWrapper'
import HowItWorks from '../../components/general/HowItWorks'
import DropdownWrapper from '../../components/general/DropdownWrapper'
import IconsAndNumbers from '../../components/general/IconsAndNumbers'
import { useQuery } from '@tanstack/react-query'
import { getHomepageData, getHowItWorksData, getSliderData } from '../../hooks/cmsApiCalls'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
import BaseSeo from '../../components/layout/BaseSeo'

export default function Homepage() {
    const { error, data, isLoading } = useQuery({
        queryKey: ['homepageData'],
        queryFn: getHomepageData,
    })

    const {
        error: howItWorksError,
        data: howItWorksData,
        isLoading: howItWorksLoading,
    } = useQuery({
        queryKey: ['howItWorksData'],
        queryFn: getHowItWorksData,
    })

    const {
        error: sliderError,
        data: sliderData,
        isLoading: sliderLoading,
    } = useQuery({
        queryKey: ['sliderData'],
        queryFn: getSliderData,
    })

    return (
        <>
            {(isLoading || sliderLoading || howItWorksLoading) && <Loading />}
            {(error || sliderError || howItWorksError) && <ErrorPage />}
            {data && sliderData && howItWorksData && (
                <>
                    <BaseSeo seo={data.attributes?.seo} />
                    <Hero
                        heading={String(data.attributes.hero_headline)}
                        subtitle={String(data?.attributes.hero_subtitle)}
                        heroImg={String(data?.attributes?.hero_img?.data?.attributes?.url)}
                    />
                    <Slider slides={sliderData || []} />
                    <ViolationOverview slides={sliderData || []} />
                    <AboutUs content={data} />
                    <Seperator content={data?.attributes?.separator1} />
                    <HowItWorks content={howItWorksData} homepage={true} bottomDistanceToBig />
                    <Placeholder content={data} />
                    <div className="mt-16 md:mt-0" />
                    <DropdownWrapper content={data?.attributes?.faq || undefined} />
                    <IconsAndNumbers content={data} />
                    <Seperator2 content={data?.attributes?.separator2} />
                    <div className="mt-8 md:mt-0" />
                    <NewsWrapper text={String(data?.attributes.news_text)} content={data?.attributes?.news_to_display?.data ?? []} />
                    <ContactFormWrapper />
                </>
            )}
        </>
    )
}
