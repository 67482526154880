import ReactMarkdown from 'react-markdown'
import { ReactComponent as RotesPlus } from '../../res/RotesPlus.svg'
import { customComponentsGreenFont } from '../layout/customComponents'
import gfm from 'remark-gfm'

type SeparatorType = {
    content: string
}

function Seperator2({ content }: SeparatorType) {
    return (
        <>
            <div className="w-full relative md:mb-12">
                <div className="bg-base-200 p-4 md:p-0 h-20 w-4/5 mx-auto relative flex items-center justify-center z-10 md:h-24 lg:h-28">
                    <p className="text-lg sm:text-3xl lg:text-4xl tracking-wide text-center lg:text-start p-4">
                        <ReactMarkdown components={customComponentsGreenFont} remarkPlugins={[gfm]}>
                            {content}
                        </ReactMarkdown>
                    </p>
                    <div className="absolute -bottom-8 -right-8 w-16 h-16 lg:w-32 lg:h-32 lg:-right-16 lg:-bottom-16">
                        <RotesPlus />
                    </div>
                </div>
                {/* gray box  */}
                <div className="absolute top-[4rem] right-0 h-20 w-1/5 bg-primary z-0 transform -translate-y-1/2 lg:h-28 lg:top-[6rem] lg:w-1/6"></div>
            </div>
        </>
    )
}

export default Seperator2
