import React from 'react'
import { LeistungenContentChlidType } from '../../types/Types'
import { ReactComponent as EdgeArrow } from '../../res/News Edge Icon.svg'

function ResponsibilitiesVerkehrsrecht({ content }: LeistungenContentChlidType) {
    console.log(content?.attributes)
    return (
        <div className="max-w-screen-xl mx-auto my-20 w-full lg:space-y-16 ">
            <h2 className="text-center text-4xl text-secondary px-8 md:px-0 max-w-2xl mx-auto pb-8">
                {content?.attributes.responsibility_headline1}
            </h2>
            <div className="max-w-screen-lg mx-auto px-6 md:px-0 md:flex flex-col grid grid-cols-1 sm:grid-cols-2 md:flex-row md:flex-wrap justify-center gap-4 md:gap-6 font-semibold">
                {content?.attributes?.verkehrsrecht?.map((item) => (
                    <div
                        key={item.responsibility}
                        className="relative sm:max-w-xs px-4 py-2 md:px-8 md:py-4 border-1 border border-primary w-full md:max-w-s text-center justify-center items-center">
                        <div className="absolute -left-0.5 -top-0.5 z-10">
                            <EdgeArrow className="h-[25px] w-[25px]" />
                        </div>
                        {/* This is the container that should directly center its children */}
                        <h4 className="flex justify-center items-center" style={{ height: '100%' }}>
                            {item.responsibility}
                        </h4>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ResponsibilitiesVerkehrsrecht
