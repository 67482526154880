import { useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { HashLink } from 'react-router-hash-link'
import { API } from '../../services/constants'
import { MdInfoOutline } from 'react-icons/md'
import { SliderType } from '../../types/Types'

type SliderComponentType = {
    slides: SliderType
}

export default function Slider({ slides }: SliderComponentType) {
    const splideRef = useRef<any>(null)

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }

    return (
        <div className="hidden md:flex w-full h-full bg-base-200 flex-col items-center z-0">
            <div className="md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl pt-10 flex justify-center items-center relative overflow-x-clip">
                <div className="absolute w-[200px] md:w-[95%] xl:w-[95%] h-16">
                    <svg
                        onClick={handlePrev}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="cursor-pointer w-16 h-16 left-0 absolute text-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    <svg
                        onClick={handleNext}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="cursor-pointer w-16 h-16 right-0 absolute text-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>

                <Splide
                    options={{
                        type: 'loop',
                        perPage: 5,
                        perMove: 1,
                        autoplay: true,
                        width: '85%',
                        pagination: false,
                        arrows: false,
                        breakpoints: {
                            1400: {
                                perPage: 4,
                                width: '85%',
                            },
                            1024: {
                                perPage: 3,
                                width: '85%',
                            },
                        },
                    }}
                    ref={splideRef}>
                    {slides.attributes.slider_content.map((slide) => (
                        <SplideSlide key={slide.id} className="overflow-hidden">
                            <div className="w-full flex items-center justify-center">
                                <HashLink to={'/' + slide.icon.data.attributes.alternativeText + '#_'}>
                                    <div className="flex flex-col items-center z-20">
                                        <img
                                            className="w-32 h-32"
                                            src={API.img + slide.icon.data.attributes.url}
                                            alt={slide.icon.data.attributes.name}
                                        />
                                        <p className="font-medium text-lg text-black z-50 mt-2 text-center">{slide.icon.data.attributes.caption}</p>
                                    </div>
                                </HashLink>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
            <div className="flex space-x-2 pb-3 text-sm items-center pt-4">
                <MdInfoOutline size={26} />
                <p>{slides.attributes.info_phrase}</p>
            </div>
            <hr className="h-px w-[80vw] rounded-full bg-black border-0 dark:bg-gray-500"></hr>
        </div>
    )
}
