import RichTextBody from './RichTextBody'

type AboutImgTextType = {
    bottomDistanceToBig?: boolean
    content: {
        first_heading: string
        description: string
    }
}

function AboutImgText(props: AboutImgTextType) {
    // if (!content || !content.attributes) return null;
    return (
        <div
            className={`pb-16 sm:pb-20 md:pb-24 lg:pb-32 pt-8 px-8 sm:px-4 md:px-8 max-w-screen-xl flex flex-col justify-center items-center mx-auto`}>
            <>
                <h2 className={`text-center text-4xl w-full sm:pt-8 md:pt-16 sm:w-fit `}>{props.content?.first_heading} </h2>
                <hr className={`h-px w-3/12 mr-auto mt-2 mb-8 bg-black border-0 mx-auto sm:mx-0`} />
                <RichTextBody className={`text-center max-w-screen-lg`} richText={props.content?.description} />
            </>
        </div>
    )
}

export default AboutImgText
