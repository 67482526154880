import './style/scrollbar.css'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { motion } from 'framer-motion'
import { Navigate, Route, Routes } from 'react-router-dom'
import Loading from './components/common/Loading'
import BaseLayout from './components/layout/BaseLayout'
import Homepage from './pages/Homepage/Homepage'
import UeberUns from './pages/About/UeberUns'
import Leistungen from './pages/Leistungen/Leistungen'
import Impressum from './pages/Impressum/Impressum'
import Datenschutz from './pages/Datenschutz/Datenschutz'
import ErrorPage from './pages/404/ErrorPage'
import FAQ from './pages/FAQ/FAQ'

// temporary
import News from './pages/News/News'
import Rotlichtverstoss from './pages/Subpages/Rotlichtverstoss'
import Handyverstoss from './pages/Subpages/Handyverstoss'
import IllegaleAutorennen from './pages/Subpages/IllegaleAutorennen'
import Abstandsverstoss from './pages/Subpages/Abstandsverstoss'
import Alkoholdelikte from './pages/Subpages/Alkoholdelikte'
import AndererVerstoss from './pages/Subpages/AndererVerstoss'
import Geschwindigkeitsverstoss from './pages/Subpages/Geschwindigkeitsverstoss'
import ParkAbschleppmassnahmen from './pages/Subpages/ParkAbschleppmassnahmen'
import Portal from './platform/pages/Portal'
import ScrollToHashElement from './components/common/SmoothScroll'
import { useQuery } from '@tanstack/react-query'
import { getSeoData } from './api/apiCalls'
import { Helmet } from 'react-helmet'
import Bearbeitungsfrist from './pages/Bearbeitungsfrist/Bearbeitungsfrist'

const GA_TRACKING_ID = 'G-D9P9GZ533M' // hier ANPASSEN

function App() {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [presence, setPresence] = useState(false)

    const handleLoading = () => {
        setLoading(false)
    }

    // this hook is just nessecary as long as there are no loading conditions like axios.get
    useEffect(() => {
        setTimeout(() => {
            handleLoading()
        }, 30)
    })

    useEffect(() => {
        if (localStorage.getItem('b4d13b60-56e7-4802-9607-d8f796d99771') === null) {
            setPresence(true)
            setVisible(true)
        } else if (localStorage.getItem('b4d13b60-56e7-4802-9607-d8f796d99771')) {
            enableAnalytics()
        }
    }, [])

    const handleAcceptAll = () => {
        localStorage.setItem('b4d13b60-56e7-4802-9607-d8f796d99771', 'true') // das anpassen!
        setVisible(!visible)
        setTimeout(() => {
            setPresence(!presence)
        }, 550)
        enableAnalytics()
    }

    const handleAcceptNecessary = () => {
        setVisible(!visible)
        setTimeout(() => {
            setPresence(!presence)
        }, 550)
    }

    // enables Google Analytics
    // hier ANPASSEN
    const enableAnalytics = () => {
        ReactGA.initialize(GA_TRACKING_ID)
    }

    return (
        <main className="relative overflow-y-scroll h-screen font-effra">
            {loading && <Loading />}
            {presence && !loading && (
                <motion.div
                    initial={{
                        y: 0,
                        opacity: 1,
                    }}
                    animate={{
                        y: !visible ? '10%' : 0,
                        opacity: !visible ? 0 : 1,
                    }}
                    transition={{
                        ease: 'linear',
                        duration: 0.3,
                    }}
                    className={`flex fixed bottom-0 md:bottom-16 md:right-16 w-full h-fit md:w-[36rem] md:min-h-[20rem] bg-base-100 md:rounded-xl flex-col justify-center p-8 md:border-primary md:border-2 z-[5000]`}>
                    <h4 className="text-lg sm:text-xl md:text-2xl font-bold text-black underline pb-2">
                        Diese Website nutzt Cookies & Google Analytics!
                    </h4>
                    <p className="text-black text-xs">
                        Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns
                        helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen),
                        z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung
                        Ihrer Daten finden Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder
                        anpassen.
                    </p>
                    <div className="flex flex-col-reverse justify-center items-center sm:flex-row w-full sm:justify-between mt-6">
                        <button onClick={handleAcceptNecessary} className="text-black/20 hover:text-black/30 text-xs">
                            Nur notwendige zulassen
                        </button>
                        <button onClick={handleAcceptAll} className="btn btn-success btn-outline w-48 mb-4 sm:mb-0">
                            Bestätigen
                        </button>
                    </div>
                </motion.div>
            )}
            {!loading && (
                <div className={'flex flex-col w-full min-h-screen'}>
                    <Routes>
                        <Route path="/" element={<BaseLayout children={<Homepage />} />} />
                        <Route path="/about" element={<BaseLayout children={<UeberUns />} />} />
                        <Route path="/leistungen" element={<BaseLayout children={<Leistungen />} />} />
                        <Route path="/FAQ" element={<BaseLayout children={<FAQ />} />} />
                        <Route path="/impressum" element={<BaseLayout children={<Impressum />} />} />
                        <Route path="/datenschutz" element={<BaseLayout children={<Datenschutz />} />} />
                        <Route path="/bearbeitungsfrist" element={<BaseLayout children={<Bearbeitungsfrist />} />} />
                        {/* <Route
                            path="/agb"
                            element={
                                <BaseLayout
                                    seo={data?.data?.attributes?.agb}
                                    children={<AGB />}
                                />
                            }
                        /> */}

                        <Route path="/news" element={<BaseLayout children={<News />} />} />
                        <Route path="/news/:newsSlug/" element={<BaseLayout children={<News />} />} />
                        <Route path="/404" element={<BaseLayout title="Page Not Found" children={<ErrorPage />} />} />
                        <Route
                            path="/portal/*"
                            element={
                                <>
                                    <Helmet>
                                        <title>Anwaltsplattform</title>
                                        <link rel="icon" href={'/public/anwaltsplattform_logo.png'} />
                                        <link rel="icon" type="image/png" href={'/public/anwaltsplattform_logo.png'} />
                                        <link rel="apple-touch-icon" href={'/public/anwaltsplattform_logo.png'} />
                                    </Helmet>
                                    <Portal />
                                </>
                            }
                        />
                        <Route path="*" element={<Navigate to="/404" />} />

                        <Route path="/rotlichtverstoss" element={<BaseLayout children={<Rotlichtverstoss />} />} />
                        <Route path="/handyverstoss" element={<BaseLayout children={<Handyverstoss />} />} />
                        <Route path="/illegale-autorennen" element={<BaseLayout children={<IllegaleAutorennen />} />} />
                        <Route path="/abstandsverstoss" element={<BaseLayout children={<Abstandsverstoss />} />} />
                        <Route path="/alkoholdelikte" element={<BaseLayout children={<Alkoholdelikte />} />} />
                        <Route path="/anderer-verstoss" element={<BaseLayout children={<AndererVerstoss />} />} />
                        <Route path="/geschwindigkeitsverstoss" element={<BaseLayout children={<Geschwindigkeitsverstoss />} />} />
                        <Route path="/park-abschleppmassnahmen" element={<BaseLayout children={<ParkAbschleppmassnahmen />} />} />
                    </Routes>
                    <ScrollToHashElement />
                </div>
            )}
        </main>
    )
}

export default App
