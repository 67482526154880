import { useEffect, useRef, useState } from 'react'
import BasePanelHeadline from './BasePanelHeadline'
import { ReactComponent as EdgeArrow } from './../../res/News Edge Icon.svg'
import { useAddMessageMutation } from '../api/services'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useKeycloak } from '@react-keycloak/web'

type BasePanelChatType = {
    uid: string
    cid: string
    sender?: string
    senderName: string
    status: boolean
    chat?: {
        sender: string
        timestamp: string
        message: string
    }[] // TODO required
}

export default function BasePanelChat({ uid, cid, sender, senderName, status, chat }: BasePanelChatType) {
    // Basic Code to scroll Chat on its last position
    const chatContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (chatContainerRef.current) {
            const scrollHeight = chatContainerRef.current.scrollHeight
            const height = chatContainerRef.current.clientHeight
            const maxScrollTop = scrollHeight - height
            chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
        }
    }, [chat])
    const { keycloak } = useKeycloak()
    const [message, setmessage] = useState<string>('')
    const queryClient = useQueryClient()
    const { mutate: sendMessage } = useAddMessageMutation(queryClient)
    const handleSend = () => {
        if (!status) {
            sendMessage({
                uid: uid,
                cid: cid,
                message: {
                    sender: sender ?? 'RCLNT', // TODO add user which is logged in -> beachte Model!
                    timestamp: dayjs().toString(),
                    message: message,
                },
            })
            setmessage('')
        }
    }

    return (
        <div className="flex flex-col gap-2 rounded-none bg-portal-gray w-1/3 max-w-[22rem] h-full p-4 relative">
            <EdgeArrow className="absolute top-0 left-0 h-8 w-8" />
            <BasePanelHeadline title={'Chat'} />
            <div className="grow" />
            <div className="flex flex-col overflow-y-scroll h-fit" ref={chatContainerRef}>
                {chat?.map((item, index) => (
                    <MessageBlock
                        key={index}
                        name={item.sender === sender ? keycloak?.idTokenParsed?.given_name + ' ' + keycloak?.idTokenParsed?.family_name : senderName}
                        sender={item.sender === sender}
                        content={item.message}
                        timestamp={item.timestamp}
                    />
                ))}
            </div>
            <div className={`${!status ? 'hidden' : 'flex'} justify-center items-center gap-2`}>
                <p className="uppercase text-gray-300 font-semibold text-sm">Der Fall ist aktuell nicht geöffnet</p>
            </div>
            <div className={`${status ? 'hidden' : 'flex'} justify-between items-end gap-2`}>
                <textarea
                    className="textarea h-24 border-secondary bg-none w-full"
                    value={message}
                    onChange={(e) => setmessage(e.target.value)}
                    style={{ backgroundColor: 'rgb(245, 245, 245)' }}
                />
                <svg
                    onClick={handleSend}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-secondary cursor-pointer">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                </svg>
            </div>
        </div>
    )
}

type MessageBlockType = {
    sender: boolean
    name: string
    content: string
    timestamp: string
}

function MessageBlock({ sender, name, content, timestamp }: MessageBlockType) {
    return (
        <div className={`chat ${!sender ? 'chat-start' : 'chat-end'}`}>
            <div className={`relative flex flex-col pt-4 ${sender && 'justify-end items-end'}`}>
                <label className={`absolute top-0 text-xs w-64 font-bold ${!sender ? 'left-0' : 'right-0 text-right'}`}>
                    {name}, {dayjs(timestamp).format('DD.MM.YYYY HH:mm')} Uhr
                </label>
                <div className="rounded-lg bg-primary/20 p-3 text-sm leading-4 max-w-[16rem]">{content}</div>
            </div>
        </div>
    )
}
